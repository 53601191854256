import React, { useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useContext } from "react";
import * as Yup from "yup";
import Header from "Components/Header/Header";
import { useHistory, useParams } from "react-router";
import { DEFAULT_COUNTRY_CODE } from "Helpers/Constants";
import ModalWithReasons from "../component/ModalWithReasons";
export const initialValue = {
  add_fund: 0,
  verified: false,
  keep_processing: false,
  refund: 0,
  reason_message: "",
  reason_message_option: 0,
  refund_flag: 0,
  mainTotal: 0,
  is_burq_order: 0,
  subscription_discount: 0,
  custom_order: 0,
  total_fund: 0,
  is_subscribe_order: 0,
  total_refund: 0,
  order_type_identifier: 0,
  parent_order_id: 0,
  replaceOrDuplicate: false,
  petal_reward: {
    petal_rewards_id: 0,
    petal_rewards_point_applied: 0,
    petal_rewards_point_amount: 0,
  },
  recipients: [
    {
      recipient_id: 0,
      sql_id: 0,
      products: [],
      addons: [],
      first_name: "",
      last_name: "",
      video_url: "",
      video_message: "",
      video_message_pass_code: "",
      video_message_qr_code: "",
      whats_the_occasion: "",
      whats_the_occasion_id: null,
      greetings_on_card: "",
      card_signature: "",
      deleted_product_ids: [],
    },
  ],
  deleted_recipient_ids: [],
  delivery_information: {
    not_deliverable_products: [],
    address_type_id: null,
    address_type: "",
    address_line_1: "",
    address_line_2: "",
    frequency: null,
    phone: "",
    alt_phone: "",
    city: "",
    state: "",
    attn: "",
    zipcode: "",
    country: "usa",
    is_local: 0,
    storeId:0,
    delivery_date: null,
    delivery_time_id: 0,
    delivery_time: "",
    delivery_date_time: "",
    delivery_time_type: "",
    delivery_type: "Delivery Service",
    actual_delivered_time: "",
    charge: 0,
    upcharge_id: 0,
    upcharge_amount: 0,
    driver_instruction: "",
    designer_instruction: "",
    delivery_type_id: 2,
    order_type: 0,
    shop_code: "",
    shop_name: "",
    person_name: "",
    external_order_number: "",
    phone_number: "",
    phone_country_code: DEFAULT_COUNTRY_CODE,
    alt_phone_country_code: DEFAULT_COUNTRY_CODE,
  },
  billing_info: {
    customer_billing_first_name: "",
    customer_billing_last_name: "",
    customer_billing_address_2: "",
    customer_billing_address_1: "",
    customer_billing_city: "",
    customer_billing_state: "",
    customer_billing_country: "United States",
    customer_billing_zipcode: "",
  },
  customer_info: {
    customer_id: 0,
    customer_first_name: "",
    customer_last_name: "",
    customer_phone: "",
    customer_email: "",
    customer_alt_phone: "",
    is_house_account: 0,
    is_business_account: 0,
    braintree_customer_id: null,
    customer_phone_country_code: DEFAULT_COUNTRY_CODE,
    customer_alt_phone_country_code: DEFAULT_COUNTRY_CODE,
  },
  payment_type: "",
  discount: {
    discount_type: "0",
    discount_reason: "",
    discount_percentage: 0,
    discount_price: 0,
  },
  tax_id: "",
  internal_notes: [],
  internal_note: "",
};

export const newRecipient = {
  recipient_id: 0,
  sql_id: 0,
  products: [],
  addons: [],
  first_name: "",
  last_name: "",
  video_url: "",
  video_message: "",
  video_message_pass_code: "",
  video_message_qr_code: "",
  whats_the_occasion: "",
  whats_the_occasion_id: null,
  greetings_on_card: "",
  card_signature: "",
  deleted_product_ids: [],
};

export const OrderAttributeContext = React.createContext({
  active: 0,
  setActive: () => null,
});

const OrderContext = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [orderDetail, setOrderDetails] = useState(null);
  const [active, setActive] = useState(0);
  const [summary, setSummary] = useState({
    total: 0,
    discount_price: 0,
    delivery_charges: 0,
    upcharge_amount: 0,
    total_qty: 0,
    tax: 0,
    total_commission_amount: 0,
    flower_total_amount: 0,
    sub_total: 0,
    add_fund: 0,
    refund: 0,
  });

  const [currentCard, setCurrentCard] = useState(null);
  const [modal, setModal] = useState("");
  const module = props.module;
  const submit = (values) => {
    // console.log(values);
  };
  const validationSchema = Yup.object({
    recipients: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()?.trim().required("First name is required"),
        last_name: Yup.string()?.trim().required("Last name is required"),
        whats_the_occasion: Yup.string().required("Occasion is required"),
        products: Yup.array().min(1, "Please add atleast one product"),
      })
    ),
    payment_type: Yup.string().required("Please select an payment type"),
    //OPEN THIS COMMENT IF WANT VALIDATION IN BILLING INFORMATION
    // Disabled Billing validation on house account, dove order and replace order and when verified checkbox checked
    // billing_info: Yup.object().when("payment_type", {
    //   is: (val) => val !== "house_account",
    billing_info: Yup.object().when(["payment_type", "verified"], {
      is: (payment_type, verified) => payment_type !== "house_account" && (orderDetail?.order_type_identifier !== 1 || module=="duplicate-order")  && payment_type !== "replace_order" && payment_type !== "dove" && !verified,
      then: Yup.object().shape({
        customer_billing_address_1: Yup.string()
          ?.trim()
          .required("Billing Address is required"),
        customer_billing_city: Yup.string()
          ?.trim()
          .required("Billing City is required"),
        customer_billing_state: Yup.string()
          ?.trim()
          .required("Billing State is required"),
        customer_billing_zipcode: Yup.string()
          ?.trim()
          .required("Billing Zipcode is required"),
      }),
    }),

    customer_info: Yup.object().shape({
      customer_first_name: Yup.string()
        ?.trim()
        .required("Customer First Name is required"),
      customer_last_name: Yup.string()
        ?.trim()
        .required("Customer Last Name is required"),
      customer_phone: Yup.string()
        ?.trim()
        .required("Customer Phone number is required"),
      customer_email: Yup.string()
        ?.trim()
        .required("Customer email is required")
        .email("Please enter valid email address"),
    }),
    delivery_information: Yup.object().shape({
      not_deliverable: Yup.array().length(0, "Product not deliverable"),
      order_type: Yup.number(),
      delivery_time_type: Yup.string()
        .when("delivery_time_id", {
          is: (val) => val === -1,
          then: Yup.string().required("Delivery Time Type is required"),
        })
        .when("address_type_id", {
          is: (val) => val > 2,
          then: Yup.string().not(
            ["Anytime"],
            "You cannot Select Anytime for Address Type other than House and Apartment"
          ),
        }),

      address_type: Yup.string().when("order_type", {
        is: (val) => val !== 1,
        then: Yup.string().required("Address Type is required"),
      }),
      address_line_1: Yup.string().when("order_type", {
        is: (val) => val !== 1,
        then: Yup.string()?.trim().required("Address Line 1 is required"),
      }),
      phone: Yup.string()
        ?.trim()
        .required("Recipient Phone number is required"),
      city: Yup.string().when("order_type", {
        is: (val) => val !== 1,
        then: Yup.string()?.trim().required("City is required"),
      }),
      state: Yup.string().when("order_type", {
        is: (val) => val !== 1,
        then: Yup.string()?.trim().required("State is required"),
      }),
      zipcode: Yup.string().when("order_type", {
        is: (val) => val !== 1,
        then: Yup.string()
          .required("Zipcode is required")
          .min(5, "Please enter valid zipcode"),
      }),
      delivery_date: Yup.string()
        .required("Delivery date is required")
        .nullable(),
      delivery_date_time: Yup.string().required("Delivery time is required"),
      delivery_type_id: Yup.number(),
      shop_code: Yup.string().when("delivery_type_id", {
        is: (val) => val > 2,
        then: Yup.string().required("Shop code is required"),
      }),
      shop_name: Yup.string().when("delivery_type_id", {
        is: (val) => val === 4,
        then: Yup.string().required("Shop name is required"),
      }),
      person_name: Yup.string().when("delivery_type_id", {
        is: (val) => val === 4,
        then: Yup.string().required("Person is required"),
      }),
      external_order_number: Yup.string().when("delivery_type_id", {
        is: (val) => val > 2,
        then: Yup.string().required("External order number is required"),
      }),
      phone_number: Yup.string().when("delivery_type_id", {
        is: (val) => val === 4,
        then: Yup.string().required("Phone number is required"),
      }),
    }),
    // payment_type: Yup.string().required("Please select an payment type"),
  });

  const duplicate = () => {
    history.push(`/admin/orders/duplicate-order/${id}`);
  };
  const replace = () => {
    history.push(`/admin/orders/replace-order/${id}`);
  };
  const ref = useRef();


  
  useEffect(() => {
    window.dummyRecipient = {
      recipient_id: 0,
      sql_id: 0,
      products: [],
      addons: [],
      first_name: "",
      last_name: "",
      video_url: "",
      video_message: "",
      video_message_pass_code: "",
      video_message_qr_code: "",
      whats_the_occasion: "",
      whats_the_occasion_id: null,
      greetings_on_card: "",
      card_signature: "",
      deleted_product_ids: [],
    };
  }, []);

  useEffect(() => {
    if (props.module === "new-order") {
      ref.current.setValues((v) => {
        return {
          ...v,
          recipients: [window.dummyRecipient],
        };
      });
    }
  }, []);
  return (
    <OrderAttributeContext.Provider
      value={{
        active,
        setActive,
        summary,
        setSummary,
        module,
        currentCard,
        setCurrentCard,
        orderDetail,
        setOrderDetails,
        modal,
        setModal,
      }}
    >
      <Formik
        innerRef={ref}
        validationSchema={validationSchema}
        initialValues={initialValue}
        onSubmit={submit}
      >
      <>
        <Header
          title={props.title}
          handleDuplicateOrderClick={duplicate}
          handleReplaceOrderClick={replace}
          hasDuplicateOrderButton={
            props.module === "edit-order" && !orderDetail?.is_subscribe_order
          }
          hasReplaceOrderButton={
            props.module === "edit-order" &&
            !orderDetail?.is_subscribe_order &&
            orderDetail?.order_type_identifier !== 1
          }
          subscriptionChecbox={
            props.module === "new-order" ? true : false
          }
          hasFraudOrderButton={props.module === "edit-order"}
          handleFraudOrderClick={(e) => {
            e.preventDefault();
            setModal("fraud");
          }}
        />
      
        <Form autoComplete="off">{props.children}</Form>
        </>
      </Formik>
    </OrderAttributeContext.Provider>
  );
};

export default OrderContext;

export function useOrderAttributeProvider() {
  const {
    active,
    setActive,
    summary,
    setSummary,
    module,
    currentCard,
    setCurrentCard,
    orderDetail,
    setOrderDetails,
    modal,
    setModal,
  } = useContext(OrderAttributeContext);
  return {
    active,
    setActive,
    summary,
    setSummary,
    module,
    currentCard,
    setCurrentCard,
    orderDetail,
    setOrderDetails,
    modal,
    setModal,
  };
}
