import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Text from "../../Components/FormControl/Text/Text";
// Context
import { AppContext } from "./../../Context/AppContext";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { DEFAULT_COUNTRY_CODE_NAME, MODULES } from "./../../Helpers/Constants";
// Services
// import SettingsApi from "./../../Services/Settings/Settings";
import { update, getByConfigurationKey } from "./../../api/settings";
import { formatUsPhone, checkValidEmailId } from "./../../Hooks/commanUtility";
// import PhoneInput from "react-phone-input-2";
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";

import { storeDetails, updateStoreDetails,updateStoreWiseBurqStatus } from "api/stores";
import moment from "moment";
import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";
import StoreSettingOpenCloseTime from "./StoreSettingOpenCloseTime";
import SwitchBox from "Components/FormControl/SwitchBox/SwitchBox";
import style from "Components/Settings/index.module.scss"


const InfoSettings = () => {
  // const settingsService = new SettingsApi();

  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [storeSettingInfo, setStoreSettingInfo] = useState({
    title: "",
    local_phone1: "",
    local_phone2: "",
    fax: "",
    email_id_main: "",
    email_id_replay_to: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    state_matches: "",
    zip: "",
    business_hours: "",
    location_hours_line1: "",
    location_hours_line2: "",
    instagram_id: "",
    instagram_credentials: "",
    burq_status:false,
  });
  const [title, setTitle] = useState(null);
  const [local_phone1, setLocalPhone1] = useState("");
  const [local_phone2, setLocalPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [email_id_main, setEmailIdMain] = useState("");
  const [email_id_replay_to, setEmailIdReplayTo] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setStateName] = useState("");
  const [state_matches, setStateMatches] = useState("");
  const [zip, setZip] = useState("");
  const [business_hours, setBusiness_hours] = useState("");
  const [location_hours_line1, setLocationHoursLine1] = useState("");
  const [location_hours_line2, setLocationHoursLine2] = useState("");
  const [instagram_id, setInstagramId] = useState("");
  const [instagram_credentials, setInstagramCredentials] = useState("");
  const [burq_status, setBurqStatus] = useState(false);

  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  const [storeOpenCloseInfo, setStoreOpenCloseInfo] = useState([]);

  useEffect(() => {
    handleGetDesignerSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  const onChange = async (type, value) => {
    if (type === "title") setTitle(value);
    // if (type === "local_phone1") {
    //   if (value.length <= 10) {
    //     setLocalPhone1(value);
    //   }
    // }
    // if (type === "local_phone2") {
    //   if (value.length <= 10) {
    //     setLocalPhone2(value);
    //   }
    // }
    if (type === "fax") {
      if (value.length <= 10) {
        setFax(value);
      }
    }
    if (type === "email_id_main") setEmailIdMain(value);
    if (type === "email_id_replay_to") setEmailIdReplayTo(value);
    if (type === "address1") setAddress1(value);
    if (type === "address2") setAddress2(value);
    if (type === "city") setCity(value);
    if (type === "country") setCountry(value);
    if (type === "state") setStateName(value);
    if (type === "state_matches") setStateMatches(value);
    if (type === "zip") setZip(value);
    if (type === "business_hours") setBusiness_hours(value);
    if (type === "location_hours_line1") setLocationHoursLine1(value);
    if (type === "location_hours_line2") setLocationHoursLine2(value);
    if (type === "instagram_id") setInstagramId(value);
    if (type === "instagram_credentials") setInstagramCredentials(value);
    if (type === "burq_status") {
      await updateStoreWiseBurqStatus({burq_status : value});
      setBurqStatus(value);
    }

    if (type) {
      storeSettingInfo[type] = value;
      // console.log("1.0 storeSettingInfo ", value);
      setStoreSettingInfo(storeSettingInfo);
    }
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleGetDesignerSetting = async () => {
    setIsLoading(true);
    //     let response = await settingsService.getByConfigurationKey(
    //       "storeSettingInfo"
    //     );
    let response = await storeDetails();
    if (response && response.status === 200) {
      if (response.data.data["title"])
        setTitle(response.data.data["title"]);
      if (response.data.data["local_phone1"])
        setLocalPhone1(response.data.data["local_phone1"]);
      if (response.data.data["local_phone2"])
        setLocalPhone2(response.data.data["local_phone2"]);
      if (response.data.data["fax"])
        setFax(response.data.data["fax"]);
      if (response.data.data["email_id_main"])
        setEmailIdMain(response.data.data["email_id_main"]);
      if (response.data.data["email_id_replay_to"])
        setEmailIdReplayTo(response.data.data["email_id_replay_to"]);
      if (response.data.data["address1"])
        setAddress1(response.data.data["address1"]);
      if (response.data.data["address2"])
        setAddress2(response.data.data["address2"]);
      if (response.data.data["city"])
        setCity(response.data.data["city"]);
      if (response.data.data["country"])
        setCountry(response.data.data["country"]);
      if (response.data.data["state"])
        setStateName(response.data.data["state"]);
      if (response.data.data["state_matches"])
        setStateMatches(response.data.data["state_matches"]);
      if (response.data.data["zip"])
        setZip(response.data.data["zip"]);
      if (response.data.data["business_hours"])
        setBusiness_hours(response.data.data["business_hours"]);
      if (response.data.data["location_hours_line1"])
        setLocationHoursLine1(response.data.data["location_hours_line1"]);
      if (response.data.data["location_hours_line2"])
        setLocationHoursLine2(response.data.data["location_hours_line2"]);
      if (response.data.data["instagram_id"])
        setInstagramId(response.data.data["instagram_id"]);
      if (response.data.data["instagram_credentials"])
        setInstagramCredentials(
          response.data.data["instagram_credentials"]
        );
      if(response.data.data["store_settings_openclose_times"]){
        let store_open_close_times = response.data.data["store_settings_openclose_times"];
        let storeSettingsOpenCloseTimesObj = await store_open_close_times.map((rdata) => {
          if (rdata?.open_value) {
            let openTime = rdata.open_value;

            openTime = openTime.split(":");
            rdata["open_hrs"] = openTime[0];
            if (rdata["open_hrs"].length === 1) rdata["open_hrs"] = "0" + rdata["open_hrs"];
            rdata["open_min"] = openTime[1];
            let twentyFourFormat = moment(rdata["open_hrs"] + ":" + rdata["open_min"], "HH:mm").format("hh:mm A");
            let commonDateTimeConvert = DateTime.fromFormat(twentyFourFormat, "hh:mm a", { zone: UTC_ZONE }).setZone(PST_ZONE)

            rdata["open_hrs"] = commonDateTimeConvert.toFormat("hh");
            rdata["open_min"] = commonDateTimeConvert.toFormat("mm");
            rdata["open_meridiem"] = commonDateTimeConvert.toFormat("a");
          }
          if (rdata?.close_value) {
            let closeTime = rdata.close_value;

            closeTime = closeTime.split(":");
            rdata["close_hrs"] = closeTime[0];
            if (rdata["close_hrs"].length === 1) rdata["close_hrs"] = "0" + rdata["close_hrs"];
            rdata["close_min"] = closeTime[1];
            let twentyFourFormat = moment(rdata["close_hrs"] + ":" + rdata["close_min"], "HH:mm").format("hh:mm A");
            let commonDateTimeConvert = DateTime.fromFormat(twentyFourFormat, "hh:mm a", { zone: UTC_ZONE }).setZone(PST_ZONE)

            rdata["close_hrs"] = commonDateTimeConvert.toFormat("hh");
            rdata["close_min"] = commonDateTimeConvert.toFormat("mm");
            rdata["close_meridiem"] = commonDateTimeConvert.toFormat("a");
          }
          return rdata;
        });
        setStoreOpenCloseInfo(storeSettingsOpenCloseTimesObj);
      }
      if (response.data.data["burq_status"]) setBurqStatus(response.data.data["burq_status"]);
      setStoreSettingInfo(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    let zipVal = Number(zip);
    // console.log("1.0 zip==> ", zip, zipVal);
    if (!storeSettingInfo) {
      showToastMessage(
        undefined,
        "Please enter max allocation",
        "error",
        false
      );
      event.preventDefault();
    } else if (email_id_main && !checkValidEmailId(email_id_main)) {
      showToastMessage(
        undefined,
        "You have entered an invalid main email address!",
        "error",
        false
      );
      event.preventDefault();
      // } else if (email_id_replay_to && !checkValidEmailId(email_id_replay_to)) {
      //   showToastMessage(
      //     undefined,
      //     "You have entered an invalid replay to email address!",
      //     "error",
      //     false
      //   );
      //   event.preventDefault();
    } else if (zip && zip <= 0) {
      showToastMessage(
        undefined,
        "Please enter valid positive values only in zip code",
        "error",
        false
      );
      event.preventDefault();
    } else if ((zip && zip.length < 5) || zip.length > 5) {
      showToastMessage(
        undefined,
        "Please enter valid zip code with 5 digit length.",
        "error",
        false
      );
      event.preventDefault();
    } else if (zip && _.isInteger(zipVal) == false) {
      showToastMessage(
        undefined,
        "Please enter valid integer number",
        "error",
        false
      );
      event.preventDefault();
    } else {
      event.preventDefault();
      setSubmitStatus(true);

      if (
        title &&
        local_phone1 &&
        // local_phone2 &&
        // fax &&
        email_id_main &&
        // email_id_replay_to &&
        address1 &&
        // address2 &&
        city &&
        country &&
        state &&
        // state_matches &&
        zip &&
        business_hours &&
        // location_hours_line1 &&
        // location_hours_line2 &&
        instagram_id
        // instagram_credentials
      ) {

        setIsLoading(true);
        // storeSettingInfo.local_phone1 = local_phone1.replace(/[#_ ]/g, "");
        // storeSettingInfo.local_phone2 = local_phone2.replace(/[#_ ]/g, "");
        storeSettingInfo.fax = fax.replace(/[#_ ]/g, "");
        // let requestBody = {
        //   key: "storeSettingInfo",
        //   value: JSON.stringify(storeSettingInfo),
        // };

        // let response = await settingsService.update(requestBody);
        storeSettingInfo.store_settings_openclose_times = storeOpenCloseInfo;
        let response = await updateStoreDetails(storeSettingInfo);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);

          setSubmitStatus(false);

          handleGetDesignerSetting();
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Basic Information</h3>
      </div>
      <div className="row">
        <div className="col-md-8">
          <Text
            type="text"
            label="Title"
            value={title}
            required={true}
            id="title"
            submitStatus={submitStatus}
            onChange={(data) => onChange("title", data)}
          />
        </div>
      </div>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Contact Information</h3>
      </div>
      <div className="row">
        <div className="col-md-4">
          {/* <Text
            type="text"
            label="Local Phone"
            value={formatUsPhone(local_phone1)}
            required={true}
            id="local_phone1"
            submitStatus={submitStatus}
            onChange={(data) => onChange("local_phone1", data)}
          /> */}

          <PhoneNumber
            countryCode={DEFAULT_COUNTRY_CODE_NAME}
            specialLabel={"Local Phone"}
            initValue={local_phone1}
            fieldName={`Local Phone`}
            onChange={(phone, countryData, event, formatedValue) => {
              onChange("local_phone1", formatedValue);
            }}
          />
        </div>
        <div className="col-md-4">
          {/* <Text
            type="text"
            label="Local Phone 2"
            value={formatUsPhone(local_phone2)}
            required={true}
            id="local_phone2"
            submitStatus={submitStatus}
            onChange={(data) => onChange("local_phone2", data)}
          /> */}

          <PhoneNumber
            countryCode={DEFAULT_COUNTRY_CODE_NAME}
            specialLabel={"Local Phone 2"}
            initValue={local_phone2}
            fieldName={`Local Phone 2`}
            onChange={(phone, countryData, event, formatedValue) => {
              onChange("local_phone2", formatedValue);
            }}
          />
        </div>
        <div className="col-md-4">
          <Text
            type="text"
            label="Fax"
            value={formatUsPhone(fax)}
            // required={true}
            id="fax"
            submitStatus={submitStatus}
            onChange={(data) => onChange("fax", data)}
          />
        </div>
        <div className="col-md-4">
          <Text
            type="text"
            label="Main"
            value={email_id_main}
            required={true}
            id="main"
            submitStatus={submitStatus}
            onChange={(data) => onChange("email_id_main", data)}
          />
        </div>
        <div className="col-md-4">
          <Text
            type="emain"
            label="Reply To"
            value={email_id_replay_to}
            // required={true}
            id="reply_to"
            submitStatus={submitStatus}
            onChange={(data) => onChange("email_id_replay_to", data)}
          />
        </div>
      </div>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Address</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Text
            type="text"
            label="Address"
            value={address1}
            required={true}
            id="address"
            submitStatus={submitStatus}
            onChange={(data) => onChange("address1", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="Address 2"
            value={address2}
            // required={true}
            id="address_2"
            submitStatus={submitStatus}
            onChange={(data) => onChange("address2", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="City"
            value={city}
            required={true}
            id="city"
            submitStatus={submitStatus}
            onChange={(data) => onChange("city", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="Country"
            value={country}
            required={true}
            id="country"
            submitStatus={submitStatus}
            onChange={(data) => onChange("country", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="State"
            value={state}
            required={true}
            id="state"
            submitStatus={submitStatus}
            onChange={(data) => onChange("state", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="State Matches"
            value={state_matches}
            // required={true}
            id="state_matches"
            submitStatus={submitStatus}
            onChange={(data) => onChange("state_matches", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="number"
            label="Zip"
            value={zip}
            required={true}
            id="zip"
            submitStatus={submitStatus}
            onChange={(data) => onChange("zip", data)}
          />
        </div>
      </div>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Other Information</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Text
            type="text"
            label="Business Hours"
            value={business_hours}
            required={true}
            id="business_hours"
            submitStatus={submitStatus}
            onChange={(data) => onChange("business_hours", data)}
          />
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <Text
            type="text"
            label="Location Hours Line 1"
            value={location_hours_line1}
            // required={true}
            id="location_hours_line1"
            submitStatus={submitStatus}
            onChange={(data) => onChange("location_hours_line1", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="Location Hours Line 2"
            value={location_hours_line2}
            // required={true}
            id="location_hours_line_2"
            submitStatus={submitStatus}
            onChange={(data) => onChange("location_hours_line2", data)}
          />
        </div>
      </div>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Instagram Information</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Text
            type="text"
            label="ID"
            value={instagram_id}
            required={true}
            id="instagram_id"
            submitStatus={submitStatus}
            onChange={(data) => onChange("instagram_id", data)}
          />
        </div>
        <div className="col-md-6">
          <Text
            type="text"
            label="Credentials"
            value={instagram_credentials}
            // required={true}
            id="instagram_credentials"
            submitStatus={submitStatus}
            onChange={(data) => onChange("instagram_credentials", data)}
          />
        </div>
      </div>


      <StoreSettingOpenCloseTime storeOpenCloseInfo={storeOpenCloseInfo} setStoreOpenCloseInfo={setStoreOpenCloseInfo} />

      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">On-Demand Status</h3>
      </div>
      <div className="row">
        <div className={`col-md-6 ${style.burq_status_toggle_button}`}>
          <SwitchBox
            value={burq_status}
            disabled={false}
            onChange={(data) => onChange("burq_status", data)}
          />
        </div>
      </div>

      <div className="d-block text-left mt-5 mb-4">
        {access.write_access && (
          <button
            onClick={(e) => handleSubmitClick(e)}
            className="btn pink-btn bold"
          >
            Save
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
export default InfoSettings;
