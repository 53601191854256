import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getRevenueBreakdownData } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import ExportIcon from "../../../Assets/images/export.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";

import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { getAccessByModuleName } from "Routes/Routes";
import { MODULES } from "Helpers/Constants";
import { setAllStore } from "Helpers/setAllStore";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";

const RevenueBreakdown = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  let accessExport = getAccessByModuleName(
    modules,
    MODULES.REVENUE_BREAKDOWN_REPORT
  );
  // const [data, setData] = React.useState([]);
  const [dataCategory, setDataCategory] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  const [dataLocalNonLocal, setDataLocalNonLocal] = React.useState([]);
  const [dataPayment, setDataPayment] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [chartData, setChartData] = React.useState(null);
  const [paiChartData, setPaiChartData] = React.useState(null);
  const [paiChartRawData, setPaiChartRawData] = React.useState([]);
  const [paiChartDataCount, setPaiChartDataCount] = React.useState(null);
  const [paiChartRawDataCount, setPaiChartRawDataCount] = React.useState([]);
  const [type, setType] = useState("delivery_date");
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const reference = React.useRef(null);

  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );
  
  const [checkAllStore,setCheckAllStore] = useState(true);

  const fetchDataTest = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {
    return true;
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setChartData(null);
    setPaiChartData(null);
    // setPaiChartRawData({});
    setPaiChartDataCount(null);
    // setPaiChartRawDataCount({});
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    // if (accessExport && accessExport.permissions.write_access)
    //   handleExportRevenue(sortBy, filters, fromDateDefault, toDateDefault);
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getRevenueBreakdownData(postData);
    if (response && response.status === 200) {
      // let finalResponse = [
      //   ...response.data.data.revenueBreakDown,
      //   ...response.data.data.revenueBreakDownOrderData,
      //   ...response.data.data.revenueBreakDownOrderCount,
      //   ...response.data.data.revenueBreakDownPayment,
      // ];
      // setData(finalResponse);

      setPageCount(0);

      let chartKey = response.data.data.revenueBreakDown.map((p) => p.title);
      let chartValue = response.data.data.revenueBreakDown.map((p) => p.status);
      let sumRevenueBreakDown = 0;
      response.data.data.revenueBreakDown.forEach((data) => {
        if (data.id !== "rewards" && data.id !== "coupons") {
          sumRevenueBreakDown += +data.status;
        } else {
          sumRevenueBreakDown -= +data.status;
        }
      });

      response.data.data.revenueBreakDown = [
        ...response.data.data.revenueBreakDown,
        ...[{ title: "Total", status: sumRevenueBreakDown.toFixed(2), id: "" }],
      ];
      setDataCategory(response.data.data.revenueBreakDown);

      let chartKeyOrder = response.data.data.revenueBreakDownOrderData.map(
        (p) => p.title
      );
      let chartValueOrder = response.data.data.revenueBreakDownOrderData.map(
        (p) => p.status
      );
      const sumRevenueBreakDownOrderData = chartValueOrder
        .reduce((result, number) => result + number)
        .toFixed(2);
      response.data.data.revenueBreakDownOrderData = [
        ...response.data.data.revenueBreakDownOrderData,
        ...[{ title: "Total", status: sumRevenueBreakDownOrderData, id: "" }],
      ];
      setDataSource(response.data.data.revenueBreakDownOrderData);

      let chartKeyOrderPayment = response.data.data.revenueBreakDownPayment.map(
        (p) => p.title
      );
      let chartValueOrderPayment = response.data.data.revenueBreakDownPayment.map(
        (p) => parseFloat(p.status)
      );

      const sumRevenueBreakDownPayment = chartValueOrderPayment
        .reduce((result, number) => result + number)
        .toFixed(2);
      response.data.data.revenueBreakDownPayment = [
        ...response.data.data.revenueBreakDownPayment,
        ...[{ title: "Total", status: sumRevenueBreakDownPayment, id: "" }],
      ];
      setDataPayment(response.data.data.revenueBreakDownPayment);

      chartKey = [...chartKey, ...chartKeyOrder, ...chartKeyOrderPayment];
      chartValue = [
        ...chartValue,
        ...chartValueOrder,
        ...chartValueOrderPayment,
      ];
      let chartSetData = {
        labels: chartKey,
        datasets: [
          {
            label: "Revenue($)",
            data: chartValue,
            backgroundColor: "rgb(228,224,229)",
          },
        ],
      };
      setChartData(chartSetData);

      let paiChartKey = response.data.data.revenueBreakDownOrderData.map(
        (p) => p.title + " %"
      );
      let paiChartValue = response.data.data.revenueBreakDownOrderData.map(
        (p) => p.per
      );

      const paiChartDataSet = {
        labels: paiChartKey,
        datasets: [
          {
            // label: "# of Votes",
            data: paiChartValue,
            backgroundColor: ["#CB998D", "#938497", "#E4E0E5"],
            borderColor: ["#CB998D"],
            borderWidth: 0.1,
            hoverOffset: 4,
            // borderRadius:
          },
        ],
      };
      setPaiChartData(paiChartDataSet);
      setPaiChartRawData(response.data.data.revenueBreakDownOrderData);

      let paiChartCountKey = response.data.data.revenueBreakDownOrderCount.map(
        (p) => p.title + " %"
      );
      let paiChartCountValue = response.data.data.revenueBreakDownOrderCount.map(
        (p) => p.per
      );
      let paiChartCountValueStatus = response.data.data.revenueBreakDownOrderCount.map(
        (p) => p.status
      );
      const sumRevenueBreakDownOrderCount = paiChartCountValueStatus.reduce(
        (result, number) => result + number
      );

      response.data.data.revenueBreakDownOrderCount = [
        ...response.data.data.revenueBreakDownOrderCount,
        ...[
          {
            title: "Total",
            status: sumRevenueBreakDownOrderCount.toFixed(2),
            id: "",
          },
        ],
      ];
      setDataLocalNonLocal(response.data.data.revenueBreakDownOrderCount);

      const paiChartDataCountSet = {
        labels: paiChartCountKey,
        datasets: [
          {
            // label: "# of Votes",
            data: paiChartCountValue,
            backgroundColor: ["#CB998D", "#E4E0E5"],
            borderColor: ["#CB998D"],
            borderWidth: 0.1,
            hoverOffset: 4,
            // borderRadius:
          },
        ],
      };
      setPaiChartDataCount(paiChartDataCountSet);
      setPaiChartRawDataCount(response.data.data.revenueBreakDownOrderCount);
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setChartData(null);
    setPaiChartData(null);
    // setPaiChartRawData({});
    setPaiChartDataCount(null);
    // setPaiChartRawDataCount({});
    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      toDateDefault: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    });
    // handleExportRevenue(event.pageIndex, event.pageSize, event.globalFilter);
  };

  const handleExportRevenue = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {

/*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    let postData = {
      // currentPage: pageIndex,
      // itemsPerPage: pageSize,
      // globalSearch: globalFilter,
      sortBy,
      filters,
      isExport: true,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };
    const response = await getRevenueBreakdownData(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const columnsCat = React.useMemo(
    () => [
      {
        Header: "Revenue by Category",
        accessor: "title",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Revenue",
        accessor: "status",
        isVisible: true,
        Cell: ({ value }) => "$" + value,
      },
    ],
    []
  );
  const columnsSource = React.useMemo(
    () => [
      {
        Header: "Revenue by Source",
        accessor: "title",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Revenue",
        accessor: "status",
        isVisible: true,
        Cell: ({ value }) => "$" + value,
      },
    ],
    []
  );
  const columnsLocalNonLocal = React.useMemo(
    () => [
      {
        Header: "Local / Non local",
        accessor: "title",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Revenue",
        accessor: "status",
        isVisible: true,
        Cell: ({ value }) => "$" + value,
      },
    ],
    []
  );
  const columnsPayment = React.useMemo(
    () => [
      {
        Header: "Revenue by Payment",
        accessor: "title",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Revenue",
        accessor: "status",
        isVisible: true,
        Cell: ({ value }) => "$" + value,
      },
    ],
    []
  );

  // const options1 = {
  //   cutoutPercentage: 100,
  //   responsive: true,
  //   tooltips: {
  //     enabled: false,
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  // };

  const options = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Header title="Revenue Breakdown" />
      <div id="MainContent" className="main-content">
        <div className={"row "}>
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Select
              label={selectedPeriodLabel}
              value={selectedPeriod}
              onChange={(data) => {
                setSelectedPeriod(data);
                handleSelectedPeriod(data);
              }}
              options={[
                { id: "weekly", title: "Weekly" },
                { id: "monthly", title: "Monthly" },
                { id: "yearly", title: "Yearly" },
                { id: "custom", title: "Custom" },
              ]}
              disabled={false}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter From"
              value={fromDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(fromDate) => {
                setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                handleFromDate(fromDate);
              }}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter To"
              value={toDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(toDate) => {
                setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                handleToDate(toDate);
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <CheckBox
            value={checkAllStore}
            label="All Stores"
            onChange={(data)=>{setCheckAllStore(data)}}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="td-actions pt-4">
              <span
                onClick={(e) => fetchData(defaultFilter)}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
              >
                <img className={styles.searchIcon} src={SearchIcon} alt="" />
              </span>
              <span
                onClick={(e) => handleClearSearch(e)}
                className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
              >
                <img className={styles.clearIcon} src={CloseIcon} alt="" />
              </span>
              {exportData && exportData.length > 0 && (
                <CSVLink
                  ref={reference}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                  data={exportData}
                  headers={exportHeader}
                  filename={"RevenueBreakdownReport.csv"}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                >
                  DownLoad
                </CSVLink>
              )}
              {accessExport && accessExport.permissions.write_access && (
                <span
                  onClick={(e) => handleExportRevenue(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                  style={{ float: "right" }}
                >
                  <img className={styles.searchIcon} src={ExportIcon} alt="" />
                </span>
              )}
            </div>
          </div>
        </div>
        {selectedPeriod !== "custom" && (
          <div className="row">
            <div className="col-md-9">
              {chartData && chartData?.datasets?.length !== 0 && (
                <Bar data={chartData} options={options} height={200} />
              )}
            </div>
            <div className="col-md-3">
              <div>
                {paiChartData && paiChartData?.datasets?.length !== 0 && (
                  <Doughnut
                    data={paiChartData}
                    // options={options1}
                    // height={10}
                    // width={10}
                    style={{ marginLeft: "50px" }}
                  />
                )}

                <div className={`d-flex mt-4 ${styles.PieChartList}`}>
                  <ul style={{ listStyle: "disc" }}>
                    {paiChartRawData.map((d, i) => {
                      return (
                        <li key={i}>
                          {d.title} - <span>${d.status}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div>
                {paiChartDataCount &&
                  paiChartDataCount?.datasets?.length !== 0 && (
                    <Doughnut
                      data={paiChartDataCount}
                      // options={options1}
                      // height={110}
                      // width={110}
                      style={{ marginLeft: "50px" }}
                    />
                  )}
                <div className={`d-flex mt-4 ${styles.PieChartList}`}>
                  <ul style={{ listStyle: "disc" }}>
                    {paiChartRawDataCount.map((d, i) => {
                      return (
                        <li key={i}>
                          {d.title} - <span>${d.status}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <br /> <br />
        <ReactTable
          columns={columnsCat}
          data={dataCategory}
          fetchData={fetchDataTest}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
        <ReactTable
          columns={columnsSource}
          data={dataSource}
          fetchData={fetchDataTest}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
        <ReactTable
          columns={columnsLocalNonLocal}
          data={dataLocalNonLocal}
          fetchData={fetchDataTest}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
        <ReactTable
          columns={columnsPayment}
          data={dataPayment}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(RevenueBreakdown);
